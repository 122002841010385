.navbar-menu li.active a {
  background: transparent !important;
}
.navbar-menu>li a {
  border-bottom-color: transparent !important;
}
.navbar-menu>li a:hover {
  background: transparent !important;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-shadow: none;
    box-sizing: border-box;
}

html{
    position: relative;
    margin: 0px !important;
    min-height: 100%;
}

body{
    margin-bottom: 160px !important;
    background-color: #ececec !important;
    font: 400 14px Roboto, Sans-serif;
    background: #F0F0F5;
    -webkit-font-smoothing: antialiased;
    min-width: 340px;
}

.btn.focus, .btn:focus,button,button:focus{
  outline: none;
  box-shadow: none;
}

header { margin-top: 80px;}
header .container{display: flex;}
header nav{
  position: fixed !important;
  width: 100%;
  z-index: 99;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  background: #FFF;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  top: 0;
}

header nav .navbar-nav a{
  padding: 15px 15px !important;
  font-weight: bold;
}
header nav .navbar-nav .home{color: #000 !important;}
header nav .navbar-nav .empresa{color: #0faab9 !important;}
header nav .navbar-nav .projetos{color: #df4913 !important;}
header nav .navbar-nav .clientes{color: #88be42 !important;}
header nav .navbar-nav .contato{color: #4f2d80 !important;}

header nav .navbar-nav .nav_pre_a{
  margin-top: 28px;
}

header nav .navbar-nav .nav_pre_a:hover .home,
header nav .navbar-nav .nav_pre_a .home.active{border-color: #000 !important;}

header nav .navbar-nav .nav_pre_a:hover .empresa,
header nav .navbar-nav .nav_pre_a .empresa.active{border-color: #0faab9 !important;}

header nav .navbar-nav .nav_pre_a:hover .projetos,
header nav .navbar-nav .nav_pre_a .projetos.active{border-color: #df4913 !important;}

header nav .navbar-nav .nav_pre_a:hover .clientes,
header nav .navbar-nav .nav_pre_a .clientes.active{border-color: #88be42 !important;}

header nav .navbar-nav .nav_pre_a:hover .contato,
header nav .navbar-nav .nav_pre_a .contato.active{border-color: #4f2d80 !important;}

header nav .navbar-nav .nav_pre_a a{
  border-bottom: 5px solid;
  border-color: transparent;
}

@media screen and (max-width: 991px) {
  header nav .navbar-nav .nav_pre_a a{
    border-bottom: 0px solid;
    border-left: 5px solid;
    border-color: transparent;
  }
  header nav .navbar-nav .nav_pre_a{
    margin: 0;
  }

  header nav .navbar-collapse{
    position: absolute;
    left: 0;
    top: 80px;
    display: block;
    width: 100%;
    background: #FFF;
    border-top: 1px solid #e7e7e7;
  }
}

header button.navbar-toggler{
  border:2px solid #e02629;
  border-color: #e02629 !important;
  color: #e02629 !important;
}
header button.navbar-toggler:hover{
  border-color: #e02629 !important;
  background: #e02629;
  color: #FFF !important;
}
header button.navbar-toggler .navbar-toggler-icon{
  color: #e02629 !important;
}
header button.navbar-toggler:hover .navbar-toggler-icon{
  color: #FFF;
}

.navbar-brand{
  height: 80px;
  padding: 0;
  margin: 0;
}
header .logo{
  padding: 15px 0px;
  max-height: 80px;
}

.navbar-pattern {
  width: 100%;
  height: 80px !important;
  padding: 0px !important;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.navbar-pattern table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.navbar-pattern table tbody > tr > td:nth-child(1) {
  background: url('./assets/header-pattern.png') 0 0;
  background-position: right;
  background-size: cover;
  width: 25px;
}

@media screen and (min-width: 768px) {
  .navbar-pattern > table > tbody > tr > td:nth-child(1) {
    width: auto;
  }
  .navbar-pattern > table > tbody > tr > td:nth-child(2) {
    width: 715px;
  }
  .navbar-pattern table tbody > tr > td:nth-child(3) {
    width: auto;
  }
}
@media screen and (min-width: 992px) {
  .navbar-menu > li:last-child {
    margin-right: 0px;
  }
  .navbar-pattern > table > tbody > tr > td:nth-child(2) {
    width: 940px;
  }
}
@media screen and (min-width: 1200px) {
  .navbar-pattern > table > tbody > tr > td:nth-child(2) {
    width: 1145px;
  }
}
@media screen and (min-width: 1700px) {
  .navbar-pattern table tbody > tr > td:nth-child(1) {
    background-size: contain;
  }
  .home-pulcher-studio .pattern {
    background-size: contain !important;
  }
}

.div-footer .footer-wp-icon {
  margin-left: -2px;
  width: 15px;
}
.div-footer a, .div-footer a:hover {
  color: #000;
}
.div-footer h5 {
  color: #e02629;
  font-weight: bold;
  font-size: 14px;
}
.div-footer table {
  margin-bottom: 1em;
}
.div-footer table > tbody > tr > td {
  color: #000;
  vertical-align: middle;
}
.div-footer table > tbody > tr > td i {
  color: #e02629 !important;
  font-size: 16px;
  font-size: 1.6rem;
}
.icon {
  width: 30px;
  text-align: center;
}
.div-footer .footer-social-link {
  color: #e02629 !important;
  font-size: 14px;
  font-size: 1.4rem;
  margin-right: 1em;
}
.div-footer .footer-social-link:last-child {
  margin-right: 0em;
}
.div-footer .footer-social-link:hover {
  text-decoration: none;
}
.all-rights {
  text-align: center;
  font-weight: bold;
  margin: 0;
}
footer {
  background: #fff;
  position: relative;
  padding-top: 0px;
  position: absolute;
  height: auto;
  width: 100%;
}
footer .icon{
  color: #e02629;
}
footer .row-info {
  margin-bottom: 5px;
  margin-top: 15px;
}

.footer-pattern {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.footer-pattern .footer-pattern-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}
.footer-pattern .footer-pattern-wrapper .footer-pattern-cell {
  display: table-cell;
}
.footer-pattern .footer-pattern-wrapper .footer-pattern-cell:first-child {
  width: 50%;
}
.footer-pattern .footer-pattern-wrapper .footer-pattern-cell:last-child {
  width: 50%;
  padding: 0px 0px 0px 0px;
}
.footer-pattern .footer-pattern-wrapper .footer-pattern-cell:last-child .pattern-image {
  background: url('./assets/footer-celular.png') 0 0;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 768px) {
  footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 160px;
  }
  .div-footer.col-custom-social {
    width: 141px !important;
  }
  .div-footer.col-custom-local {
    width: 320px !important;
  }
  .div-footer.col-custom-address {
    width: 237px !important;
  }
  .footer-pattern .footer-pattern-wrapper .footer-pattern-cell:first-child {
    width: 50%;
  }
  .footer-pattern .footer-pattern-wrapper .footer-pattern-cell:last-child {
    width: 50%;
    padding: 0px 0px 0px 300px;
  }
  .footer-pattern .footer-pattern-wrapper .footer-pattern-cell:last-child .pattern-image {
    background: url('./assets/pattern_footer.png') 0 0;
    background-size: auto 100%;
    background-position: left;
    background-repeat: no-repeat;
  }
}

h1{
    
    font-size: 20px;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0px;
    text-align: center;
    margin: 35px 0;
}
.bar{
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 2px;
    margin: 0 15px;
}