.container-contato .bar{
    background: #4f2d80 !important;
}

.container-contato h1,
.container-contato .icon{
    color: #4f2d80 !important;
}

.container-contato h4{
    color: #4f2d80;
    margin-bottom: 1em;
    font-size: 19px;
    font-size: 1.2rem;
}
.container-contato a{
    color: #333;
}
.container-contato table tr{
    margin-bottom: 1rem;
    display: flex;
}
.container-contato table tr td{
    align-self: center;
}
.container-contato .contato-map{
    border-top: 4px solid #4f2d80;
    border-bottom: 4px solid #4f2d80;
    padding: 0;
    margin: 0 0 30px 0;
    width: 100%;
}
.container-contato .contato-map .google_maps{
    height: 300px;
    padding: 0;
    margin: 0;
    width: 100%;
    display: block;
}


.container-contato .ps-form-submit {
    font-size: 14px;
    font-size: 1em;
    font-weight: 500;
    border: 2px solid #4f2d80;
    border-radius: 0px;
    float: right;
    background: #4f2d80;
    color: #fff;
    line-height: 18px;
    padding: 0.6em 1em 0.4em;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
}
.container-contato .ps-form-submit:hover {
  background: #fff;
  color: #4f2d80;
}

.container-contato .ps-input-group {
  position: relative;
  margin-bottom: 12px;
  margin-top: 12px;
}
.container-contato .ps-input-group .opcional {
  font-size: 10px;
  text-transform: none;
}
.container-contato .ps-input-group span {
  box-shadow: unset;
  border: none;
}
.container-contato .ps-input-group textarea {
  resize: none;
  height: 130px;
  line-height: 20px !important;
  padding: 14px;
}
.container-contato .ps-input-group .ps-form-input {
  background: #d0d1d0;
  border-radius: 0px;
  min-height: 38px;
  line-height: 28px;
  padding-left: 14px;
  border: none;
  box-shadow: unset;
  width: 100%;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.container-contato .ps-input-group .label {
    position: absolute;
    top: 12px;
    left: 10px;
    pointer-events: none;
    color: #696969;
    text-transform: uppercase;
    font-size: 12px;
    font-size: 0.8rem;
    font-weight: 700;
    padding: .2em .6em .3em;
    line-height: 1;
    white-space: nowrap;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
}
.input_ativo input,
.input_ativo select,
.input_ativo textarea{
    background: #fff !important;
    border: 2px solid #4f2d80 !important;
}
.input_ativo .label,
.container-contato .ps-input-group .label-after {
  top: -18px !important;
  left: -6px !important;
  color: #696969 !important;
}
.container-contato .ps-input-group .ps-form-dropdown {
  display: block;
  width: 100%;
  height: 38px;
}
.container-contato .ps-input-group .ps-form-dropdown .ps-form-input {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 0;
}
.container-contato .ps-input-group .ps-form-dropdown:after {
  position: absolute;
  content: '\f107';
  color: #000;
  font-family: FontAwesome;
  right: 10px;
  top: 10px;
  pointer-events: none;
}
.container-contato .ps-input-group .ps-form-dropdown-after:after {
  content: '\f106';
  color: #4f2d80;
  font-family: FontAwesome;
}
.container-contato .ps-input-group .ps-form-dropdown-options {
  display: none;
  position: absolute;
  background: #fff;
  width: 100%;
  margin-top: -10px;
  z-index: 9;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}
.container-contato .ps-input-group .ps-form-dropdown-options p {
  padding: 10px 17px;
  cursor: pointer;
}
.container-contato .ps-input-group .ps-form-dropdown-options p:hover {
  background: #ececec;
}
.container-contato .ps-input-group .ps-form-dropdown-options-after {
  display: block;
}
.container-contato .ps-input-group .select-hidden {
  display: none;
  visibility: hidden;
}
.container-contato .ps-input-group .select {
  background: #d0d1d0;
  border-radius: 0px;
  min-height: 38px;
  line-height: 28px;
  padding-left: 14px;
  margin-top: 15px;
  border: 2px solid #d0d1d0;
  width: 100%;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}
.container-contato .ps-input-group .ps-select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}
.container-contato .ps-input-group .ps-select-options li {
  line-height: 28px;
  padding-left: 16px;
  height: 28px;
  cursor: pointer;
}
.container-contato .ps-input-group .ps-select-options li:hover {
  background: #ececec;
}
.container-contato .ps-input-group .ps-select-options-after {
  display: block;
}
.container-contato .ps-form-select p {
  margin: 0;
  line-height: 28px;
  padding: 3px 0;
}
.container-contato .wpcf7-validation-errors {
  border-color: #88be42;
}
@media screen and (min-width: 768px) {
  #contato-second-row .contato-map {
    height: 300px;
  }
  .container-contato .ps-input-group textarea {
    height: 300px;
  }
}